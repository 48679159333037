<template>
    <div>
        <promotion-form></promotion-form>
    </div>
</template>

<script>
import PromotionForm from '@/components/pages/promotion/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        PromotionForm,
    },
    mixins: [dirtyForm],
}
</script>
