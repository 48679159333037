<template>
    <base-form
        ref="form"
        v-model="form"
        :api="apiClientType.RESIDENTIAL_CANTEEN_RELATIONS"
        :mapper="mapperType.RESIDENTIAL_CANTEEN_RELATIONS_FORM"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        @created="created"
        @updated="updated"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <!-- RESIDENTIAL CANTEEN RELATIONS -->

        <v-col cols="12">
            <v-card color="grey lighten-5" flat>
                <v-card-title primary-title>
                    <h3 class="headline">
                        {{ $t('menu.residential_canteen_relations') }}
                    </h3>
                </v-card-title>
                <v-card-text>
                    <div v-if="form" class="row">
                        <div class="col-lg-12">
                            <!-- Residential Canteen related restaurant -->
                            <entity-select-input
                                v-model="form.restaurant"
                                v-validate.immediate="''"
                                label="residential_canteen_relation.restaurant"
                                name="restaurant"
                                :item-value="form.restaurant"
                                :entity="selectApiClientType.RESTAURANT"
                                :query-params="{ cloudCanteenPartnerOnly: 1 }"
                                :data-vv-as="
                                    $t(
                                        'residential_canteen_relation.restaurant'
                                    )
                                "
                                :error-messages="errors.collect('restaurant')"
                            ></entity-select-input>
                        </div>
                        <div class="col-lg-12">
                            <!-- Residential Canteen addresses -->

                            <entity-select-input
                                v-model="form.address"
                                v-validate.immediate="''"
                                label="residential_canteen_relation.address"
                                name="address"
                                :entity="
                                    selectApiClientType.RESIDENTIAL_CANTEEN_ADDRESS
                                "
                                :data-vv-as="
                                    $t('residential_canteen_relation.address')
                                "
                                :error-messages="errors.collect('address')"
                            ></entity-select-input>
                        </div>

                        <div class="col-lg-12">
                            <!-- residential canteen relation delivery time -->
                            <text-input
                                v-model="form.deliveryTime"
                                v-validate="'required|min:1|max:100|space'"
                                name="deliveryTime"
                                label="residential_canteen_relation.delivery_time"
                                :error-messages="errors.collect('deliveryTime')"
                                :data-vv-as="
                                    $t(
                                        'residential_canteen_relation.delivery_time'
                                    )
                                "
                                type="number"
                            ></text-input>

                            <!-- residential canteen relation delivery fee -->
                            <text-input
                                v-model="form.deliveryFee"
                                v-validate="'required|min:1|max:100|space'"
                                name="deliveryFee"
                                label="residential_canteen_address.delivery_fee"
                                :error-messages="errors.collect('deliveryFee')"
                                :data-vv-as="
                                    $t(
                                        'residential_canteen_address.delivery_fee'
                                    )
                                "
                            ></text-input>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </base-form>
</template>

<script>
import routeType from '@/router/routeType'
import FormExtend from '@/components/mixins/FormExtend'
import SelectApiClientType from '@/api/SelectApiClientType'

export default {
    mixins: [FormExtend],
    data() {
        return {
            selectApiClientType: SelectApiClientType,
            entity: 'Residential Canteen relation',
            editRoute: routeType.RESIDENTIAL_CANTEEN_RELATIONS_EDIT,
            listRoute: routeType.RESIDENTIAL_CANTEEN_RELATIONS,
        }
    },
    computed: {
        title() {
            return this.entityId
                ? this.$t('residential_canteen_relation.edit_page_title', {
                      entityName: `${this.form?.restaurantName} <-> ${this.form?.addressName}`,
                  })
                : this.$t('residential_canteen_relation.create_page_title')
        },
    },
    methods: {
        editRedirectParams: (data) => ({ entityId: data.id }),
    },
}
</script>
