<template>
    <base-form
        ref="form"
        v-model="form"
        :api="apiClientType.PROMOTIONS"
        :mapper="mapperType.PROMOTIONS_FORM"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        @created="created"
        @updated="updated"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <!-- PROMOTION -->

        <v-col cols="12">
            <v-card color="grey lighten-5" flat>
                <v-card-title primary-title>
                    <h3 class="headline">{{ $t('labels.promotion') }}</h3>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col-lg-2 offset-lg-1">
                            <!-- promotion name -->
                            <text-input
                                v-model="form.name"
                                v-validate="'required|min:3|max:100|space'"
                                name="name"
                                label="labels.name"
                                :error-messages="errors.collect('name')"
                                :data-vv-as="$t('labels.name')"
                            ></text-input>
                        </div>
                        <div class="col-lg-2">
                            <!-- promotion type -->
                            <entity-select-input
                                v-model="form.promotionType"
                                v-validate="'required'"
                                label="labels.type"
                                name="promotionType"
                                :entity="selectApiClientType.PROMOTION_TYPES"
                                :data-vv-as="$t('labels.type')"
                                :error-messages="
                                    errors.collect('promotionType')
                                "
                                translate-items
                            ></entity-select-input>
                        </div>
                        <div class="col-lg-3">
                            <!-- company -->
                            <entity-select-input
                                v-model="form.company"
                                v-validate="
                                    form.promotionType ===
                                        promotionType.company ||
                                    form.promotionType ===
                                        promotionType.allowance
                                        ? 'required'
                                        : ''
                                "
                                :entity="selectApiClientType.COMPANY"
                                label="labels.company"
                                :data-vv-as="$t('labels.company')"
                                autocomplete
                                name="companies"
                                :error-messages="errors.collect('companies')"
                            ></entity-select-input>
                        </div>
                        <div class="col-lg-3">
                            <!-- users -->
                            <div
                                v-if="form.promotionType === promotionType.user"
                            >
                                <entity-select-input
                                    key="user"
                                    v-model="form.users"
                                    v-validate="'required'"
                                    :entity="selectApiClientType.USER"
                                    label="labels.user"
                                    :data-vv-as="$t('labels.user')"
                                    autocomplete
                                    name="users"
                                    :error-messages="errors.collect('users')"
                                    multi-select
                                    :query-params="{ company: form.company }"
                                    :request-disabled="form.company === null"
                                ></entity-select-input>
                            </div>
                            <!-- allowanes -->
                            <div
                                v-if="
                                    form.promotionType ===
                                    promotionType.allowance
                                "
                            >
                                <entity-select-input
                                    key="allowance"
                                    v-model="form.allowances"
                                    v-validate="'required'"
                                    :entity="
                                        selectApiClientType.COMPANY_ALLOWANCE
                                    "
                                    label="labels.allowance"
                                    :data-vv-as="$t('labels.allowance')"
                                    autocomplete
                                    name="allowances"
                                    :error-messages="
                                        errors.collect('allowances')
                                    "
                                    multi-select
                                    :query-params="{ company: form.company }"
                                    :request-disabled="form.company === null"
                                ></entity-select-input>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>

        <!-- DISCOUNT -->

        <v-col cols="12">
            <v-card color="grey lighten-5" flat>
                <v-card-title primary-title>
                    <h3 class="headline">{{ $t('labels.discount') }}</h3>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col offset-xl-1">
                            <!-- discount type -->
                            <entity-select-input
                                v-model="form.discountType"
                                v-validate="'required'"
                                label="labels.discount_type"
                                name="discountType"
                                class="d-inline-block"
                                style="margin-right: 30px"
                                :entity="
                                    selectApiClientType.PROMOTION_DISCOUNT_TYPES
                                "
                                :error-messages="errors.collect('discountType')"
                                :data-vv-as="$t('labels.discount_type')"
                                translate-items
                            ></entity-select-input>
                            <!-- discount value -->
                            <text-input
                                v-model.number="form.discountValue"
                                v-validate="
                                    form.discountType ===
                                    promotionDiscountType.percentage
                                        ? 'required|min_value:0|max_value:100|decimal:2'
                                        : 'required|min_value:0|decimal:2'
                                "
                                label="labels.discount"
                                name="discountValue"
                                type="number"
                                :suffix="
                                    form.discountType ===
                                    promotionDiscountType.percentage
                                        ? '%'
                                        : currencyCode
                                "
                                class="d-inline-block"
                                style="max-width: 200px"
                                :error-messages="
                                    errors.collect('discountValue')
                                "
                                :data-vv-as="$t('labels.discount')"
                            ></text-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col offset-xl-1">
                            <!-- user budget only -->
                            <checkbox-input
                                v-model="form.userBudgetOnly"
                                label="labels.user_budget_only"
                                name="userBudgetOnly"
                                class="d-block d-xl-inline-block"
                                style="margin-right: 10%"
                            ></checkbox-input>
                            <!-- allow coupons -->
                            <checkbox-input
                                v-model="form.allowCoupons"
                                label="labels.allow_coupons"
                                name="allowCoupons"
                                class="d-block d-xl-inline-block"
                                style="margin-right: 10%"
                            ></checkbox-input>
                            <!-- include delivery fee -->
                            <checkbox-input
                                v-model="form.includeDeliveryFee"
                                name="includeDeliveryFee"
                                label="labels.include_delivery_fee"
                                class="d-block d-xl-inline-block"
                                style="margin-right: 10%"
                                @input="includeDeliveryFeeChange"
                            ></checkbox-input>
                            <!-- delivery fee remmission -->
                            <checkbox-input
                                v-model="form.deliveryFeeRemission"
                                name="deliveryFeeRemission"
                                label="labels.delivery_fee_remission"
                                class="d-block d-xl-inline-block"
                                @input="deliveryFeeRemissionChange"
                            ></checkbox-input>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>

        <!-- TERMS -->

        <v-col cols="12">
            <v-card color="grey lighten-5" flat>
                <v-card-title primary-title>
                    <h3 class="headline">{{ $t('labels.discount_terms') }}</h3>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col-xl-4 col-lg-6 offset-xl-1">
                            <!-- minimum total -->
                            <currency-input
                                v-model.number="form.minimumTotal"
                                v-validate="'required|min_value:0|decimal:2'"
                                label="labels.minimum_cart_value"
                                name="minimumTotal"
                                style="max-width: 200px"
                                :error-messages="errors.collect('minimumTotal')"
                                :data-vv-as="$t('labels.minimum_cart_value')"
                            >
                            </currency-input>
                        </div>
                        <div class="col-xl-4 col-lg-6 offset-xl-1">
                            <!-- restaurants -->
                            <entity-select-input
                                v-model="form.restaurants"
                                :entity="selectApiClientType.RESTAURANT"
                                label="labels.only_for_selected_restaurants"
                                :data-vv-as="
                                    $t('labels.only_for_selected_restaurants')
                                "
                                name="restaurants"
                                multi-select
                                autocomplete
                                :error-messages="errors.collect('restaurants')"
                            ></entity-select-input>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 50px">
                        <div class="col-xl-4 col-lg-6 offset-xl-1">
                            {{ $t('labels.date_period') }}
                            <div class="row">
                                <div class="col">
                                    <!-- date from -->
                                    <date-picker-input
                                        v-model="form.dateFrom"
                                        v-validate="
                                            form.dateTo != null
                                                ? 'required'
                                                : ''
                                        "
                                        label="labels.from"
                                        name="dateFrom"
                                        :max="form.dateTo"
                                        :min="today"
                                        :data-vv-as="$t('labels.from')"
                                        :error-messages="
                                            errors.collect('dateFrom')
                                        "
                                        only-date
                                    ></date-picker-input>
                                </div>
                                <div class="col">
                                    <!-- date to -->
                                    <date-picker-input
                                        v-model="form.dateTo"
                                        v-validate="
                                            form.dateFrom != null
                                                ? 'required'
                                                : ''
                                        "
                                        label="labels.to"
                                        :data-vv-as="$t('labels.to')"
                                        name="dateTo"
                                        :min="
                                            form.dateFrom == null
                                                ? today
                                                : form.dateFrom
                                        "
                                        :error-messages="
                                            errors.collect('dateTo')
                                        "
                                        only-date
                                    ></date-picker-input>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-6 offset-xl-1">
                            {{ $t('labels.time_period') }}
                            <div class="row">
                                <div class="col">
                                    <!-- time from -->
                                    <date-picker-input
                                        v-model="form.timeFrom"
                                        v-validate="
                                            form.timeTo != null
                                                ? 'required'
                                                : ''
                                        "
                                        label="labels.from"
                                        :data-vv-as="$t('labels.from')"
                                        name="timeFrom"
                                        :max="form.timeTo"
                                        :error-messages="
                                            errors.collect('timeFrom')
                                        "
                                        only-time
                                    ></date-picker-input>
                                </div>
                                <div class="col">
                                    <!-- time to -->
                                    <date-picker-input
                                        v-model="form.timeTo"
                                        v-validate="
                                            form.timeFrom != null
                                                ? 'required'
                                                : ''
                                        "
                                        label="labels.to"
                                        :data-vv-as="$t('labels.to')"
                                        name="timeTo"
                                        :min="form.timeFrom"
                                        :error-messages="
                                            errors.collect('timeTo')
                                        "
                                        only-time
                                    ></date-picker-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </base-form>
</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import routeType from '@/router/routeType'
import promotionType from '@/enum/promotionType'
import promotionDiscountType from '@/enum/promotionDiscountType'
import FormExtend from '@/components/mixins/FormExtend'

export default {
    mixins: [FormExtend],
    data() {
        return {
            form: {
                name: '',
                promotionType: promotionType.company,
                company: null,
                allowances: [],
                users: [],
                discountType: promotionDiscountType.percentage,
                discountValue: 0,
                userBudgetOnly: false,
                includeDeliveryFee: false,
                deliveryFeeRemission: false,
                minimumTotal: 0,
                restaurants: [],
                dateFrom: null,
                dateTo: null,
                timeFrom: null,
                timeTo: null,
                allowCoupons: false,
            },
            promotionType: promotionType,
            promotionDiscountType: promotionDiscountType,
            editRoute: routeType.PROMOTION_EDIT,
            listRoute: routeType.PROMOTION_LIST,
        }
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
        }),
        title() {
            return this.form.name
        },
        today() {
            return this.$date().format('YYYY-MM-DD')
        },
    },
    methods: {
        includeDeliveryFeeChange() {
            if (this.form.includeDeliveryFee) {
                this.form.deliveryFeeRemission = false
            }
        },
        deliveryFeeRemissionChange() {
            if (this.form.deliveryFeeRemission) {
                this.form.includeDeliveryFee = false
            }
        },
    },
}
</script>
