<template>
    <div>
        <coupon-form></coupon-form>
    </div>
</template>

<script>
import CouponForm from '@/components/pages/coupons/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        CouponForm,
    },
    mixins: [dirtyForm],
}
</script>
