<template>
    <div>
        <residential-canteen-relations-form></residential-canteen-relations-form>
    </div>
</template>

<script>
import residentialCanteenRelationsForm from '@/components/pages/residentialCanteens/relations/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        residentialCanteenRelationsForm,
    },
    mixins: [dirtyForm],
}
</script>
