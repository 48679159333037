<template>
    <base-form
        ref="form"
        v-model="form"
        :api="apiClientType.COUPONS"
        :mapper="mapperType.COUPON_FORM"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        @created="created"
        @updated="updated"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <!-- COUPON -->

        <v-col cols="12">
            <v-card color="grey lighten-5" flat>
                <v-card-title primary-title>
                    <h3 class="headline">{{ $t('coupon.details') }}</h3>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col-lg-4">
                            <!-- coupon name -->
                            <text-input
                                v-model="form.name"
                                v-validate="'required|min:1|max:100|space'"
                                name="name"
                                label="coupon.name"
                                :error-messages="errors.collect('name')"
                                :data-vv-as="$t('coupon.name')"
                            ></text-input>
                        </div>
                        <div class="col-lg-4">
                            <!-- coupon code -->
                            <text-input
                                v-model="form.code"
                                v-validate="'required|min:0|max:100|space'"
                                name="code"
                                label="coupon.type"
                                :error-messages="errors.collect('code')"
                                :data-vv-as="$t('coupon.type')"
                            ></text-input>
                        </div>
                        <div class="col-lg-4">
                            <!-- coupon value -->
                            <text-input
                                v-model="form.value"
                                v-validate="
                                    'required|min:1|decimal:2|max:100|space'
                                "
                                name="value"
                                label="coupon.value"
                                :error-messages="errors.collect('value')"
                                :data-vv-as="$t('coupon.value')"
                                type="text"
                            ></text-input>
                        </div>
                    </div>
                    <form-two-col-row>
                        <template v-slot:col1>
                            <checkbox-input
                                v-model="form.isPercent"
                                label="coupon.is_percent"
                                name="isPercent"
                            ></checkbox-input>
                        </template>
                    </form-two-col-row>
                </v-card-text>
            </v-card>
        </v-col>

        <!-- LIMITS -->

        <v-col cols="12">
            <v-card color="grey lighten-5" flat>
                <v-card-title primary-title>
                    <h3 class="headline">{{ $t('coupon.limits') }}</h3>
                </v-card-title>
                <v-card-text>
                    <form-two-col-row>
                        <template v-slot:col1>
                            <checkbox-input
                                v-model="form.appliesToDelivery"
                                label="coupon.applies_to_delivery"
                                name="appliesToDelivery"
                            ></checkbox-input>
                        </template>
                        <template v-slot:col2>
                            <checkbox-input
                                v-model="form.deliveryFreeRemission"
                                label="labels.delivery_fee_remission"
                                name="deliveryFreeRemission"
                            ></checkbox-input>
                        </template>
                    </form-two-col-row>

                    <div class="col-lg-12 mt-4">
                        <!-- coupon minimumPrice -->
                        <entity-select-input
                            v-model="form.paymentMethodList"
                            v-validate.immediate="''"
                            label="coupon.payment_methods"
                            name="paymentMethodList"
                            :entity="selectApiClientType.PAYMENT_METHODS"
                            :data-vv-as="$t('coupon.payment_methods')"
                            :error-messages="
                                errors.collect('paymentMethodList')
                            "
                            multi-select
                            translate-items
                        ></entity-select-input>
                    </div>
                    <form-two-col-row>
                        <template v-slot:col1>
                            <div class="col-lg-12">
                                <!-- coupon minimumPrice -->
                                <text-input
                                    v-model="form.minimumPrice"
                                    v-validate="'space'"
                                    name="minimumPrice"
                                    label="coupon.minimum_price"
                                    :error-messages="
                                        errors.collect('minimumPrice')
                                    "
                                    :data-vv-as="$t('coupon.minimum_price')"
                                ></text-input>
                            </div>
                        </template>
                        <template v-slot:col2>
                            <div class="col-lg-12">
                                <!-- coupon maximumPrice -->
                                <text-input
                                    v-model="form.maximumPrice"
                                    v-validate="
                                        'space|min_value:' + form.minimumPrice
                                    "
                                    name="maximumPrice"
                                    label="coupon.maximum_price"
                                    :error-messages="
                                        errors.collect('maximumPrice')
                                    "
                                    :data-vv-as="$t('coupon.maximum_price')"
                                ></text-input>
                            </div>
                        </template>
                    </form-two-col-row>

                    <form-two-col-row>
                        <template v-slot:col1>
                            <div class="col-lg-12">
                                <!-- coupon usageLimit -->
                                <text-input
                                    v-model="form.usageLimit"
                                    v-validate="'space'"
                                    name="usageLimit"
                                    label="coupon.usage_limit"
                                    :error-messages="
                                        errors.collect('usageLimit')
                                    "
                                    :data-vv-as="$t('coupon.usage_limit')"
                                    type="number"
                                ></text-input>
                            </div>
                        </template>
                        <template v-slot:col2>
                            <div class="col-lg-12">
                                <!-- coupon userUsageLimit -->
                                <text-input
                                    v-model="form.userUsageLimit"
                                    v-validate="'space'"
                                    name="userUsageLimit"
                                    label="coupon.user_usage_limit"
                                    :error-messages="
                                        errors.collect('userUsageLimit')
                                    "
                                    :data-vv-as="$t('coupon.user_usage_limit')"
                                    type="number"
                                ></text-input>
                            </div>
                        </template>
                    </form-two-col-row>
                    <div class="col-lg-12 mt-4">
                        <!-- coupon minimumPrice -->
                        <entity-select-input
                            v-model="form.restaurant"
                            v-validate.immediate="''"
                            label="coupon.restaurants"
                            name="restaurant"
                            :entity="selectApiClientType.RESTAURANT"
                            :data-vv-as="$t('coupon.restaurants')"
                            :error-messages="errors.collect('restaurant')"
                            multi-select
                        ></entity-select-input>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>

        <!-- TERMS -->

        <v-col cols="12">
            <v-card color="grey lighten-5" flat>
                <v-card-title primary-title>
                    <h3 class="headline">{{ $t('coupon.time_period') }}</h3>
                </v-card-title>
                <v-card-text>
                    <date-picker-input
                        v-model="form.dateFrom"
                        v-validate.immediate="''"
                        label="coupon.date_from"
                        name="dateFrom"
                        :error-messages="errors.collect('dateFrom')"
                        :data-vv-as="$t('coupon.date_from')"
                        only-date
                    ></date-picker-input>
                    <date-picker-input
                        v-model="form.dateUntil"
                        label="coupon.date_until"
                        name="dateUntil"
                        :error-messages="errors.collect('dateUntil')"
                        :data-vv-as="$t('coupon.date_until')"
                        only-date
                    ></date-picker-input>
                </v-card-text>
            </v-card>
        </v-col>
    </base-form>
</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import routeType from '@/router/routeType'
import FormMixin from '@/components/mixins/FormMixin'
import SelectApiClientType from '@/api/SelectApiClientType'

export default {
    mixins: [FormMixin],
    data() {
        return {
            form: {
                name: '',
                value: 0,
                minimumPrice: 0,
                maximumPrice: null,
                usageLimit: null,
                userUsageLimit: 0,
                dateFrom: null,
                dateUntil: null,
                isPercent: false,
                appliesToDelivery: false,
                restaurant: [],
                paymentMethodList: [],
                deliveryFreeRemission: false,
            },
            selectApiClientType: SelectApiClientType,
            entity: 'Coupon',
            editRoute: routeType.COUPON_EDIT,
            listRoute: routeType.COUPON_LIST,
        }
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
        }),
        title() {
            return this.form.name
        },
        today() {
            return this.$date().format('YYYY-MM-DD')
        },
    },
    methods: {},
}
</script>
