<template>
    <div>
        <residential-canteen-addresses-form></residential-canteen-addresses-form>
    </div>
</template>

<script>
import residentialCanteenAddressesForm from '@/components/pages/residentialCanteens/addresses/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        residentialCanteenAddressesForm,
    },
    mixins: [dirtyForm],
}
</script>
