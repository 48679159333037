<template>
    <div>
        <residential-canteen-form></residential-canteen-form>
    </div>
</template>

<script>
import residentialCanteenForm from '@/components/pages/residentialCanteens/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        residentialCanteenForm,
    },
    mixins: [dirtyForm],
}
</script>
