<template>
    <div>
        <v-card v-if="showWarning" outlined class="red">
            <v-card-text class="white--text">
                {{ warningMessage }}
            </v-card-text>
        </v-card>

        <base-form
            ref="form"
            v-model="form"
            :api="apiClientType.RESIDENTIAL_CANTEENS"
            :mapper="mapperType.RESIDENTIAL_CANTEENS_FORM"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :show-progress="showProgress"
            @created="created"
            @updated="updated"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <form-panel>
                <form-two-col-row>
                    <template v-slot:col1>
                        <h3 class="headline pt-3 pb-6 black--text">
                            {{ $t('labels.canteen_order_settings_title') }}
                        </h3>

                        <text-input
                            v-model="form.name"
                            v-validate="'required|max:255|space'"
                            name="name"
                            label="labels.name"
                            :error-messages="errors.collect('name')"
                            :data-vv-as="$t('labels.name')"
                        ></text-input>

                        <div class="pb-4">
                            <entity-select-input
                                v-model="form.address"
                                v-validate="'required'"
                                label="residential_canteen.address"
                                name="address"
                                :entity="
                                    selectApiClientType.RESIDENTIAL_CANTEEN_ADDRESS
                                "
                                :data-vv-as="$t('residential_canteen.address')"
                                :error-messages="errors.collect('address')"
                            ></entity-select-input>
                        </div>

                        <recur-dates-common-form-part
                            v-model="form"
                        ></recur-dates-common-form-part>
                    </template>
                    <template v-slot:col2>
                        <h3 class="headline py-6 black--text">
                            {{ $t('labels.canteen_notifications_title') }}
                        </h3>

                        <div class="pb-6">
                            <checkbox-input
                                v-model="form.sendNotificationWhenOpen"
                                name="sendNotificationWhenOpen"
                                label="labels.send_notification_when_open"
                            ></checkbox-input>
                        </div>

                        <text-input
                            v-model="form.sendNotificationBeforeCutoff"
                            v-validate="'required|numeric'"
                            type="number"
                            name="sendNotificationBeforeCutoff"
                            label="labels.send_notification_before_cutoff"
                            :suffix="$t('labels.minutes')"
                            :error-messages="
                                errors.collect('sendNotificationBeforeCutoff')
                            "
                            :data-vv-as="
                                $t('labels.send_notification_before_cutoff')
                            "
                        ></text-input>
                        <div class="g-b2--text mt-n3">
                            {{ notificationTime || '-' }}
                        </div>
                    </template>
                </form-two-col-row>
                <form-one-col-row>
                    <canteen-restaurant-form-part
                        v-model="form"
                        type="ResidentialCanteenRestaurant"
                        :restaurant-entity="
                            selectApiClientType.RESIDENTIAL_CANTEEN_RESTAURANT
                        "
                        :restaurant-query-param="{
                            residential_canteen_address_id: getAddressIdFromIri(
                                form.address
                            ),
                        }"
                    ></canteen-restaurant-form-part>
                </form-one-col-row>
            </form-panel>
        </base-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import routeType from '@/router/routeType'
import FormBase from '@/components/mixins/FormBase'
import CanteenEditDisableMixin from '@/components/mixins/CanteenEditDisableMixin'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormOneColRow from '@/components/form/FormOneColRow'
import CanteenRestaurantFormPart from '@/components/pages/canteen/CanteenRestaurantFormPart'
import RecurDatesCommonFormPart from '@/components/pages/canteen/RecurDatesCommonFormPart'
import TextInput from '@/components/form/Inputs/TextInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import IdFromIri from '@/services/IdFromIri'
import { DATE_TIME_FORMAT } from '@/const/DateTimeFormats'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        FormOneColRow,
        CanteenRestaurantFormPart,
        RecurDatesCommonFormPart,
        TextInput,
        CheckboxInput,
        EntitySelectInput,
    },
    mixins: [FormBase, CanteenEditDisableMixin],
    data() {
        let baseline = this.$date().set('minute', 0).set('second', 0)
        if (baseline.set('hour', 12).isBefore(this.$date())) {
            baseline = baseline.add(1, 'day')
        }
        const cutoff = baseline.set('hour', 10)
        const delivery = baseline.set('hour', 12)
        const open = baseline.subtract(1, 'day').set('hour', 9)
        return {
            form: {
                name: null,
                openTime: open.format(DATE_TIME_FORMAT),
                cutoffTime: cutoff.format(DATE_TIME_FORMAT),
                earliestCutoffTime: cutoff.format(DATE_TIME_FORMAT),
                deliveryTime: delivery.format(DATE_TIME_FORMAT),
                relatedRestaurants: [],
                address: null,
                pickupOffset: 15,
                isRecurring: false,
                recurringWeeks: 1,
                sendNotificationBeforeCutoff: 30,
                sendNotificationWhenOpen: false,
            },
            entity: 'label.residential_canteens',
            editRoute: routeType.RESIDENTIAL_CANTEENS_EDIT,
            listRoute: routeType.RESIDENTIAL_CANTEENS,
        }
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
        }),
        title() {
            return this.entityId
                ? this.$t('residential_canteen.edit_page_title', {
                      entityName: `${this.form.name} (${this.form.identifier})`,
                  })
                : this.$t('residential_canteen.create_page_title')
        },
        notificationTime() {
            return this.form.cutoffTime
                ? this.$date(this.form.cutoffTime)
                      .subtract(
                          this.form.sendNotificationBeforeCutoff,
                          'minutes'
                      )
                      .format('YYYY-MM-DD HH:mm')
                : ''
        },
    },
    watch: {
        ['form.relatedRestaurants']() {
            if (this.form.relatedRestaurants.length > 0 && !this.form.address) {
                this.errors.add({
                    field: 'address',
                    msg: this.$t(
                        'validations.messages.rc-address-needed-for-restaurant'
                    ),
                })
            }
        },
    },
    methods: {
        getAddressIdFromIri(addressIri) {
            if (addressIri) return IdFromIri.getId(addressIri)
        },
        editRedirectParams: (data) => ({ entityId: data.id }),
    },
}
</script>
