<template>
    <base-form
        ref="form"
        v-model="form"
        :api="apiClientType.RESIDENTIAL_CANTEEN_ADDRESSES"
        :mapper="mapperType.RESIDENTIAL_CANTEEN_ADDRESSES_FORM"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        @created="created"
        @updated="updated"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <addresses-form-part
            v-model="form"
            :hide-invoice-name="true"
        ></addresses-form-part>
    </base-form>
</template>

<script>
import routeType from '@/router/routeType'
import FormBase from '@/components/mixins/FormBase'
import AddressesFormPart from '@/components/pages/addresses/AddressesFormPart'

export default {
    components: {
        AddressesFormPart,
    },
    mixins: [FormBase],
    data() {
        return {
            form: {
                name: null,
                phone: '',
                longitude: null,
                latitude: null,
                fullAddress: null,
                houseNumber: '',
                street: '',
                town: '',
                zipCode: '',
                sublocality: '',
                administrativeAreaLevel1: '',
                administrativeAreaLevel2: '',
                administrativeAreaLevel3: '',
                administrativeAreaLevel4: '',
                country: '',
                countryCode: '',
                subAddress: '',
                placeId: '',
                comment: '',
            },
            entity: 'labels.residential_canteen_addresses',
            editRoute: routeType.RESIDENTIAL_CANTEEN_ADDRESSES_EDIT,
            listRoute: routeType.RESIDENTIAL_CANTEEN_ADDRESSES,
        }
    },
    computed: {
        title() {
            return this.entityId
                ? this.$t('residential_canteen_address.edit_page_title', {
                      entityName: `${this.form?.name}`,
                  })
                : this.$t('residential_canteen_address.create_page_title')
        },
    },
    methods: {
        editRedirectParams: (data) => ({ entityId: data.id }),
    },
}
</script>
