<template>
    <div>
        <coupon-form :entity-id="entityId"></coupon-form>
    </div>
</template>

<script>
import CouponForm from '@/components/pages/coupons/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        CouponForm,
    },
    mixins: [dirtyForm],

    data() {
        return {
            entityId: null,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.entityId = route.params.id
            },
        },
    },
}
</script>
